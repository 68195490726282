<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1 v-if="config.id != null">
            <div>Редактирование<br> конфигурации id {{ config.id }}</div>
          </h1>
          <h1 v-else>
            <div>Создание конфигурации мониторинга информационных мероприятий
              <v-btn outlined @click="$refs['fileInput'].click()">загрузить файл конфигурации</v-btn>
              <input ref="fileInput" style="display: none" type="file" @change="loadJson">
            </div>
          </h1>

          <div v-if="config.id != null" class="creation-date">Дата
            создания<span>{{ config.createdDate | dateFormat }}</span></div>

          <v-row>
            <v-col cols="12" lg="3">
              <div class="date-range-label">Период действия</div>
              <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      :value="config.startDate | dateFormat"
                      clearable
                      label=""
                      prepend-icon="mdi-calendar"
                      readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="config.startDate"
                    :max="maxDate"
                    :min="minDate"
                    locale="ru-RU"
                    no-title
                    scrollable
                    @input="startDateMenu=false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>


            <v-col cols="12" lg="3">
              <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      :value="config.endDate | dateFormat"
                      clearable
                      label=""
                      prepend-icon="mdi-calendar"
                      readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="config.endDate"
                    :max="maxDate"
                    :min="minDate"
                    locale="ru-RU"
                    no-title
                    scrollable
                    @input="endDateMenu=false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

          </v-row>


          <div class="list config">
            <div v-for="(direction, directionIndex) in config.directions" :key="direction.id" class="list-item-wrap">

              <v-expansion-panels>
                <v-expansion-panel
                    :key="direction.id"
                >
                  <v-expansion-panel-header inside @keyup.space.prevent>

                    <div class="list-item">
                      <div class="list-item-name">
                        <button class="toggleSwitch">
                          <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                          </svg>
                          <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                          </svg>
                        </button>
                        <span><h4 v-if="directionIndex===0">Направления</h4>
                      <q>{{ direction.id / 100 }}.</q><v-text-field
                              v-model="direction.name" @click.stop=""></v-text-field></span>
                        <div v-if="config.directions.length > 1"
                             class="delete"
                             @click.stop="deleteDirection(direction.id)">
                          <v-icon class="icon-item icon-delete-outline"></v-icon>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div  class="level2">
                      <div v-for="(action, actionIndex) in direction.actions" :key="direction.id+'.'+action.id" class="list-item">
                      <div class="list-item-name">
                    <span>
                      <h4 v-if="actionIndex===0">Мероприятия</h4><q>{{ direction.id / 100 }}.{{ action.id / 100 }}.</q><v-text-field
                        v-model="action.name" @click.stop=""></v-text-field>
                    </span>
                      <div v-if="direction.actions.length>1" class="delete"
                           @click="deleteAction(direction.id, action.id)"
                           @click.stop="">
                        <v-icon class="icon-item icon-delete-outline"></v-icon>
                      </div>
                      </div>
                      </div>
                    </div>
                    <a class="configAdd" href="#"
                       @click.prevent="addAction(direction)">+ Добавить мероприятие</a>
                  </v-expansion-panel-content>


                </v-expansion-panel>
              </v-expansion-panels>
            </div>


            <a class="configAdd" href="#" @click.prevent="addDirection()">+ Добавить направление</a>


          </div>


          <div class="save-buttons mt-24">
            <v-btn color="primary" outlined @click="$router.push('/config/infevents')">Отмена</v-btn>
            <v-btn :disabled="submitButtonDisabled" color="primary" @click="submit">Сохранить</v-btn>
          </div>
        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

export default {
  name: "InfeventsConfigEdit",
  props: {},
  components: {},
  data() {
    return {
      config: {
        id: null,
        startDate: null,
        endDate: null,
        directions: [{id: 100, name: "", actions: [{id: 100, name: ""}]}]
      },
      startDateMenu: false,
      endDateMenu: false,
      minDate: '2019-01-01',
      maxDate: '2030-12-31',
      errorText: null,
      submitButtonDisabled: false,
      apiLoaded: false
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  methods: {
    //удаляет направление из конфига
    deleteDirection(directionId) {
      for (let i = 0; i < this.config.directions.length; i++) {
        if (this.config.directions[i].id === directionId) {
          this.config.directions.splice(i, 1)
          break
        }
      }
      this.ordinateNumbers()
      this.$forceUpdate()
    },

    deleteAction(directionId, actionId) {
      for (let direction of this.config.directions) {
        if (direction.id === directionId) {
          for (let i = 0; i < direction.actions.length; i++) {
            if (direction.actions[i].id === actionId) {
              direction.actions.splice(i, 1)
              break;
            }
          }
          break
        }
      }
      this.ordinateNumbers()
      this.$forceUpdate()
    },

    addDirection() {
      if (this.config.directions == null) {
        this.config.directions = []
      }
      this.config.directions.push({
        id: 100,
        name: "",
        actions: [{id: 100, name: ""}]
      })
      this.ordinateNumbers()
      this.$forceUpdate();
    },

    addAction(direction) {
      direction.actions.push({id: 100, name: ""})
      this.ordinateNumbers()
      this.$forceUpdate();
    },

    //после удаления какого-то элемента порядок номеров направлений-этапов-подэтапов собъется. Нужно все снова расставить
    //при добавлении аналогично
    ordinateNumbers() {
      for (let i = 0; i < this.config.directions.length; i++) {
        let direction = this.config.directions[i]
        direction.id = i * 100 + 100
        for (let j = 0; j < direction.actions.length; j++) {
          let action = direction.actions[j]
          action.id = j * 100 + 100
        }
      }
    },

    validate(directions) {
      if (directions == null || directions.length < 1) {
        return "Должно быть хотя бы одно направление"
      }
      for (let i = 0; i < directions.length; i++) {
        let direction = directions[i]
        if (direction.id !== i * 100 + 100) {
          return "Идентификаторы должны быть кратны 100 и следовать по порядку"
        }
        if (direction.name == null || direction.name.length === 0) {
          return "Должны быть указаны названия всех элементов"
        }
        for (let j = 0; j < direction.actions.length; j++) {
          let action = direction.actions[j]
          if (action.id !== j * 100 + 100) {
            return "Идентификаторы должны быть кратны 100 и следовать по порядку"
          }
          if (action.name == null || action.name.length === 0) {
            return "Должны быть указаны названия всех элементов"
          }
        }
      }
      return true
    },

    loadJson() {
      let file = this.$refs['fileInput'].files[0];
      let reader = new FileReader();
      reader.readAsText(file);
      reader.componentThis = this
      reader.onload = function () {
        let directions

        try {
          directions = JSON.parse(reader.result)
        } catch (e) {
          reader.componentThis.errorText = "Файл должен содержать конфигурацию в формате JSON"
          return
        }
        // console.log(directions)
        let validstat = reader.componentThis.validate(directions)
        if (validstat === true) {
          reader.componentThis.config.directions = directions
        } else {
          reader.componentThis.errorText = validstat
        }
      };

      reader.onerror = function () {
        alert('Ошибка загрузки файла')
      };
    },

    async submit() {
      let valstat = this.validate(this.config.directions)
      if (valstat === true) {
        this.config.directionsJson = JSON.stringify(this.config.directions)
        this.submitButtonDisabled = true
        let req = await this.$postApi("/infevents/saveConfig", this.config)
        if (req.ok) {
          this.$router.push("/config/infevents")
        } else {
          this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
          this.submitButtonDisabled = false
        }
      } else {
        this.errorText = valstat
      }
    }
  },
  async beforeMount() {
    if (this.$route.params.id != null) {
      //режим редактирования
      let req = await this.$getApi("/infevents/getConfig", {id: this.$route.params.id})
      if (req.ok) {
        this.config = req.payload
        this.config.directions = JSON.parse(this.config.directionsJson)
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    } else {
      this.apiLoaded = true
    }
  }
};
</script>
